@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'BatangChe.ttf';
  src: url('fonts/BatangChe.ttf') format('truetype');
}

body {
  background-color: black;
  font-family: 'BatangChe.ttf';
  overflow: auto; 
  letter-spacing: -0.5px;
  position: relative;
  min-height: 100vh;
  display:flex;
    flex-direction: column;
}

@media (max-width: 1366px) {
  body {
    overflow: auto; 
  }
}

.main {
  height: 75vh;
}





.logo {
  filter: invert(1);
}

nav {
  font-family: 'BatangChe.ttf';
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(19, 19, 19);
}

.borderbottom {
  border-bottom: white 1px solid;
}
footer {
    align-self: end;
    grid-column: 2;
    background-color: white;
    bottom: 0;
    padding: 0;
    margin-top: auto;
}

.error {
  height: 100vh;
}.live {
  min-height:100vh;
position: relative;
}
.music-detail {
  min-height:100vh;
position: relative;
}

.livebutton:hover {
  border: white 4px solid;
}

.omerta-logo {
  filter: invert(1);
}

footer {
  filter: invert(1);
  border-top: 1px solid black;
}

.yama {
  filter: invert(1);
}



.header-container {
  height: 100vh;
}
